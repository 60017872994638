import { useEventListener } from '@vueuse/core';

const addEventListeners = () => {
    const readBlock = document.getElementById('read-block');
    const readLess = document.getElementById('read-less');
    const readMore = document.getElementById('read-more');

    const toggleReadMore = (showReadBlock: boolean) => {
        readBlock.classList.toggle('d-none', !showReadBlock);
        readLess.classList.toggle('d-none', !showReadBlock);
        readMore.classList.toggle('d-none', showReadBlock);
    };

    useEventListener(readMore, 'click', () => toggleReadMore(true));
    useEventListener(readLess, 'click', () => toggleReadMore(false));
};
useEventListener(document, 'DOMContentLoaded', addEventListeners);
